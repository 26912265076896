<script>
import cloneDeep from 'lodash.clonedeep';
export default {
    props: {
        selectedPaymentMethods: Object,
        hideTitle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logoList: [],
            paymentType: [],
            currentPaymentType: "",
            paymentMethods: {}
        }
    },
    created() {
        this.paymentMethods = cloneDeep(this.selectedPaymentMethods);
        if(this.selectedPaymentMethods && Object.keys(this.selectedPaymentMethods).length > 0) {
            for(let key in this.paymentMethods) {

                this.paymentMethods[key] = this.paymentMethods[key].map((element) => {
                    if(!this.selectedPaymentMethods[key]) {
                        element.is_enabled = false;
                    } else {
                        const pmtChannel = this.selectedPaymentMethods[key].find(el => el.payment_method_key === element.payment_method_key);
                        if(!pmtChannel || !pmtChannel.is_enabled) {
                            element.is_enabled = false;
                        }
                    }
                    return element;
                })
            }
            this.setPaymentMethods();
        }
    },
    methods: {
        setPaymentMethods() {
            this.logoList = [];
            this.paymentType = [];

            for(let key in this.paymentMethods) {

                if(this.paymentMethods[key].length > 0) {
                    this.paymentType.push(key);

                    this.paymentMethods[key].map(element => {
                        if(element.is_enabled) {
                            this.logoList.push(element.logo)
                        }
                    })
                }
            }
        },

        changeCurrentMethod(value) {
            if(this.currentPaymentType === value) {
                this.currentPaymentType = "";
            } else {
                this.currentPaymentType = value;
            }
        },
    }
}
</script>

<template>
    <div>
        <label v-if="!hideTitle" class="d-block">{{ $t("views.payment_links.payment_methods") }}
            <span class="float-right text-chai cursor-pointer" v-b-modal.modal-1 >View all
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g id="Iconsax/Linear/arrowright">
                        <path id="Vector" d="M10.8225 4.44727L15.375 8.99977L10.8225 13.5523M2.625 8.99977H15.2475" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </span>
        </label>
        <div class="d-flex">
            <b-img v-bind="{ src:logo, width: 30, height: 30, class: 'mx-1' }" v-for="(logo, index) in logoList.slice(0, 5)" rounded="circle" alt="Circle image" :key="index"></b-img>
            <span class="my-auto" style="font-size:16px; font-weight:500; color:rgba(0, 0, 0, 0.5); margin-left: 10px;">{{ logoList.length > 6 ? `+ ${logoList.length - 6} more` : '' }}</span>
            <span v-if="hideTitle" class="float-right text-chai cursor-pointer my-auto ml-4" v-b-modal.modal-1 >View all
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g id="Iconsax/Linear/arrowright">
                        <path id="Vector" d="M10.8225 4.44727L15.375 8.99977L10.8225 13.5523M2.625 8.99977H15.2475" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </span>
            <!-- <b-btn variant="light" class="rounded-circle mx-1" size="sm"><i class="mdi mdi-pencil"></i></b-btn> -->
        </div>


        <b-modal
            id="modal-1"
            title=""
            no-close-on-backdrop
            hide-footer
            header-class="modal-header-custom"
            body-class="modal-body-custom"
        >
            <!-- Payment Types -->
            <div>
                <p style="font-size: 20px;color: #000;margin-bottom: 30px;">Payment Method</p>
            </div>
            <div v-for="(method, index) in paymentType" :key="index" style="margin-bottom: 15px;">

                <div class="d-flex justify-content-between rounded" @click="changeCurrentMethod(method)" style="cursor: pointer; cursor: pointer;background: rgb(243, 243, 243);background: rgba(220, 218, 213, 0.30);border-top-left-radius: 8px;border-top-right-radius: 8px;padding: 15px 25px;border-radius: 8px;">
                    <div class="d-flex"><label class="my-auto" style="cursor: pointer;padding-left: 5px;font-size: 14px;color: #000;">{{ method }}</label></div>
                    <i :class="currentPaymentType === method ? 'mdi-chevron-up' : 'mdi-chevron-down'" class="h4 my-auto mdi"></i>
                </div>

                <!-- Payment Methods -->
                <div v-if="currentPaymentType === method" >
                    <div v-for="(element, key) in paymentMethods[method]" :key="key" style="padding-left: 55px; padding-top: 15px;padding-bottom: 15px;border-bottom: 1px #DCDAD5 solid;font-size: 14px;">{{ element.name }}</div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>
::v-deep .modal-header-custom{
    border-bottom: none;
    padding-bottom: 0px;
}
::v-deep .modal-body-custom{
    padding: 0px 35px 15px 35px;
}
</style>